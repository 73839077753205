import React, { Component } from "react";
import { frontendUrl } from "./../../setup";
import "./Footer.scss";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="col-md-12 col-sm-12 footer-container ">
        <div className="footer-section">
          <div className="col-md-offset-2 col-md-8 footHead">
            <div className="row">
              <p>
                Forestcoin Limited (NZBN: 9429048985116) is registered as a
                Finance Service Provider (FSP1001682) and is supervised by the
                New Zealand Department of Internal Affairs (DIA).
              </p>
            </div>
          </div>

          <div className="col-md-12 footerInfo">
            <div className="row">
              <p>
                <a
                  href={frontendUrl + "/anti-money-laundering-aml-policy/"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Anti-Money Laundering (AML) Policy
                </a>{" "}
                |{" "}
                <a
                  href={frontendUrl + "/important-information/"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Important Information
                </a>{" "}
                |{" "}
                <a
                  href={frontendUrl + "/privacy-policy/"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{" "}
                |{" "}
                <a
                  href={frontendUrl + "/terms-and-conditions/"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Condition
                </a>{" "}
                |{" "}
                <a
                  href="https://exchangeforest.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  exchangeforest.com
                </a>
              </p>
            </div>
          </div>
          <div className="copy">
            <div className="row copysub">
              <p className="footText">
                2022 © Forestcoin Limited. All Rights Reserved. Forestcoin has
                been developed using resources provided by{" "}
                <a
                  href="https://tendersoftware.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  tendersoftware.com
                </a>
              </p>

              <span className="socail-link">
                <a
                  href="https://www.instagram.com/forestcoin/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="../../../assets/images/instagram.png"
                    alt="instagram"
                  />{" "}
                </a>
                <a
                  href="https://twitter.com/forestcoinearth"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="../../../assets/images/twitter.png" alt="twitter" />{" "}
                </a>
                <a
                  href="https://www.facebook.com/forestcoin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="../../../assets/images/fb.png" alt="fb" />{" "}
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
