import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Payment from "../pages/Payment";
import QRCode from "../pages/QRCode";
import Confirmation from "../pages/Confirmation";
import { frontendUrl } from '../../src/setup';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { loadProgressBar } from 'axios-progress-bar'
import { loader } from '../redux/Actions/payment'
import 'axios-progress-bar/dist/nprogress.css'
import "./App.scss";
import 'sweetalert2/src/sweetalert2.scss'

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isConnected: true,
      changeIcon: 0,
      delayTimer: 500,
    };
  }

  componentDidMount() {
    console.log("init");
    loadProgressBar();
  }
  render() {
    return (
      <Fragment>
        <div className="loader1">
          {this.props.payment && this.props.payment.isloading ?
            // <Oval color="#7fbd2c" secondaryColor="#1a2500" strokeWidth={3} height={80} width={80} /> : ''}
            <div className="modalloading ">
              <p className="loader"></p>
            </div> : '' }
        </div>
        
        <Router>
          <Switch>
            <Route exact path='/' component={() => {
              global.window && (global.window.location.href = frontendUrl);
              return null;
            }} />

            <Route path="/payment" component={QRCode} exact={true} />
            <Route path="/success" component={Confirmation} exact={true} />

            <Route path="/:username" component={Payment} exact={true} />
            <Route path="/:username/:amount" component={Payment} exact={true} />
            <Route path="/:username/:currency/:amount" component={Payment} exact={true} />
            <Route path="/:username/:currency/:amount/:details" component={Payment} exact={true} />

            <Route path="/payment/:username" component={QRCode} exact={true} />
            <Route path="/payment/:username/:amount" component={QRCode} exact={true} />
            <Route path="/payment/:username/:currency/:amount" component={QRCode} exact={true} />
            <Route path="/payment/:username/:currency/:amount/:details" component={QRCode} exact={true} />

            <Route path="/success/:username" component={Confirmation} exact={true} />
            <Route path="/success/:username/:amount" component={Confirmation} exact={true} />
            <Route path="/success/:username/:currency/:amount" component={Confirmation} exact={true} />
            <Route path="/success/:username/:currency/:amount/:details" component={Confirmation} exact={true} />

          </Switch>
        </Router>
      </Fragment>);
  }
}

const mapStateToProps = (state, props) => {
  const { payment } = state;

  return {
    payment,
  };
};

export default connect(mapStateToProps, { loader })(
  App
);
