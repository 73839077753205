import "bootstrap/dist/css/bootstrap.css";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./layouts/App";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "./redux/store";
import { firebaseConfig, environment } from "./setup";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  const noop = () => {}
  ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn','warning'
  ].forEach((method) => {
    window.console[method] = noop
  })
}

if(environment === 'production') {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

