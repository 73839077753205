import React, { Component } from "react";
import "./Payment.scss";

class PaymentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="payment col align-self-center">
        <p>A cryptocurrency</p>
        <p>
          Where coins are <span>mined</span> by planting trees
        </p>

        <ul className="menu">
          <li>Fast</li>
          <li>Secure</li>
          <li>Regulated</li>
        </ul>
      </div>
    );
  }
}

export default PaymentDashboard;
