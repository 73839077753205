import React, { Component } from "react";
import { frontendUrl } from "./../../setup";
import { format } from 'date-fns';


class ConfirmationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = () => {
    window.location.href = frontendUrl;
  };

  render() {
    const { data, amount } = this.props;
    return (
      <div>
        {data && (
          <div className="confirm-container-main">
          <div className="confirm-container">
            <div className="transaction-success">
            <span><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-check-circle fa-w-16 fa-3x"><path fill="currentColor" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z" className=""></path></svg></span>
              <span>Transaction confirmed</span>
            </div>

            <div className="sent-receive-details">
              <div className="sent-receive-text">RECEIVED</div>
              <div className="pay-value">
                <span className="volume-trade">
                  {data.VolumeTrade}
                  <span>FC</span>
                </span>
              </div>
              <div className="currency-value">
                <span className="currencyvalue">
                  ({data.SelectedCurrency} {amount})
                </span>
              </div>
            </div>

            <div className="pay-transaction-details">
              <div className="transId">
                <p className="transIdtitle">Transaction Id</p>
                <p className="transIdvalue">{data.TransactionRequestID}</p>
              </div>
              <div className="transdate">
                <p className="transIdtitle">Date</p>
                <p className="transIdvalue">{format(new Date(data.CreatedOn), 'dd-MM-yyyy kk:mm')}</p>
              </div>
            </div>
            <div>
              <button className="backhome-button" onClick={this.props.homeClick}>
                BACK TO HOME SCREEN
              </button>
            </div>
          </div>
        </div>
        )}
      </div>
    );
  }
}

export default ConfirmationPage;
