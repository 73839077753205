import React, { Component } from "react";
import { frontendUrl } from './../../setup';
import "./Header.scss";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="header-container">
      <div className="container">
        <div className="row">
        <div className="col-xs-6 headLogo ">
          <a href={frontendUrl} target='_self' rel="noopener noreferrer">
              <img src="../../../assets/images/fc-logo.png" alt="forestcoin" />{" "}
          </a>
        </div>
        <div className="col-xs-6 text-right">
          <a href={frontendUrl} target='_blank' rel="noopener noreferrer"><button className="headtext">Learn More</button> </a>
        </div></div>
      </div>
      </div>
    );
  }
}

export default Header;
