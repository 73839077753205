const { setup: { backendUrl, signalRUrl, frontendUrl, deepLink, firebaseConfig, environment } } = window;


export {
  backendUrl,
  signalRUrl,
  frontendUrl,
  deepLink,
  firebaseConfig,
  environment
}
