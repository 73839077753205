import { paymentReducer } from "./Reducers/payment";

import { persistStore } from "redux-persist";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxThunk from "redux-thunk";

const rootReducer = combineReducers({
  payment: paymentReducer,
});
export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxThunk))
);
export const persistor = persistStore(store, null);
