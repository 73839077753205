import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from 'redux';
import {
  GET_CURRENCY_LIST,
  GET_MERCHANT_DATA,
  GET_OPEN_ORDER,
  GET_TRANSACTION_REQUEST,
  GET_GENERATE_ADDRESS,
  LOADER_LOAD
} from "../Actions/payment";

export const initialState = {
  isloading: false
};
export const initialUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENCY_LIST:
      return { ...state, currencylist: action.payload };
    case GET_MERCHANT_DATA:
      return { ...state, merchantdata: action.payload };
    case GET_OPEN_ORDER:
      return { ...state, openOrder: action.payload };
    case GET_TRANSACTION_REQUEST:
      return { ...state, transactionReq: action.payload };
    case GET_GENERATE_ADDRESS:
      return { ...state, generateAddr: action.payload };
      case LOADER_LOAD:
        return { ...state, isloading: action.payload };

    default:
      return state;
  }
};

const persistConfig = {
  key: "payment",
  storage,
  whitelist: ["currencylist"],
};
export const paymentReducer = persistReducer(persistConfig, initialUserReducer);
