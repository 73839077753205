import axios from "axios";
import { backendUrl } from './setup';

const instanceApi = axios.create({
  baseURL: backendUrl,
});

instanceApi.interceptors.request.use(
  async (axiosConfig) => {
    // const persistUser = JSON.parse(localStorage.getItem('persist:user'));
    // const newToken = JSON.parse(localStorage.getItem('token'));

    //   const token = newToken[0].token
    //   axiosConfig.headers.Authorization = 'Bearer ' + token;
    //axiosConfig.defaults.headers.common['Content-Type'] = "application/x-www-form-urlencoded;charset=utf-8";

    return axiosConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instanceApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    console.log("API FAILED2: " + error);
    const responseStatus = error.hasOwnProperty("response")
      ? error.response !== undefined
        ? error.response.status
        : null
      : null;

    if (responseStatus !== null && responseStatus === 500) {
      alert("Internal server Error", "error", 3500);
    }

    return Promise.reject(error);
  }
);

export default instanceApi;
