import React, { Component } from "react";
import PaymentDashboard from "../Payment/paymentDashboard";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Confirmation.scss";
import ConfirmationPage from "./ConfirmationPage";

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationdata :this.props.location && this.props.location.state && this.props.location.state.confirmationdata ? this.props.location.state.confirmationdata : null,
      merchantname: this.props.location && this.props.location.state && this.props.location.state.merchantname ? this.props.location.state.merchantname : null,
      amount: this.props.location.state.amount,
      invoiceDetail: this.props.location.state.detail,
      currency: this.props.location.state.currency,
      depositAddress: this.props.location.state.depositAddress,
    };
  }

  componentDidMount() {

    console.log(this.props.location.state,'this.props.location.state')
  }

  handleGotoHome = () => {
    this.props.history.push({
      pathname: "/" + this.state.merchantname + "/" + this.state.currency + "/" + this.state.amount
    });
  };

  render() {
    const {confirmationdata, amount } = this.props.location.state;
    return (
      <div className="payment-container">
        <Header></Header>
        <PaymentDashboard />
        <ConfirmationPage data={confirmationdata} amount={amount} homeClick={this.handleGotoHome}/>
        <Footer></Footer>
      </div>
    );
  }
}
export default Confirmation;
