import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { backendUrl } from "./../../setup";
import PaymentDashboard from "../Payment/paymentDashboard";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import "@fortawesome/fontawesome-free/js/all.js";
import Select from "react-select";
import {
  GetCurrencyList,
  GetMerchantData,
  GenerateAddress,
  loader,
} from "../../redux/Actions/payment";
import "./Payment.scss";
import {
  handlePaymentValidation,
  validatePay,
} from "../../Validation/Validation";
import _ from "lodash";
class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
      depositAddress: "",
      username: "",
      amount: 0,
      currency: "USD",
      detail: "",
      merchantdata: null,
      error: {},
      GoBack: false,
      merchantname: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    let paymentreload = JSON.parse(localStorage.getItem("paymentDetails"));
    let params = this.props.location.pathname.split("/");

    if(paymentreload !== null) {
      if(!(params[1] === paymentreload.merchantname && params[2] === paymentreload.currency && params[3] === paymentreload.amount)) {
        paymentreload = null;
      }
    }


    if (paymentreload === null) {
    
      params[2] = params[2] ? params[2].toUpperCase():''
      
      if (params.length > 4 || (params.length > 3 && !isNaN(+params[2]))) {
        this.setState({ merchantname : params[1]});
        this.props.history.push({
          pathname: "/payment",
          state: {
            merchantname: params[1],
            currency: (isNaN(+params[2]) ? params[2] : 'Merchant'),
            amount: !isNaN(+params[2]) ? params[2] : params[3],
            invoiceDetail: !isNaN(+params[2]) ? params[3] :params[4],
          },
        });
      } else {
        this.props.GetCurrencyList().then((rescurrency) => {
          var currencyName =
          rescurrency && rescurrency.countryList
              ? rescurrency.countryList.map((el) => ({
                  ...el,
                  label: el.currencyName,
                  value: el.currencyName,
                }))
              : [];
          let SortCurrency = _.sortBy(currencyName, "value");
          var currencyUnique = _.uniqBy(SortCurrency, "value");
          this.setState(
            {
              selectOptions: currencyUnique,
            },
    
            () => {
              var matchcurr = this.state.selectOptions.filter((curr) => curr.currencyName === params[2]);
              var LoadCurrency = matchcurr.length > 0 ?  params[2]: 0
             
              this.setState({
                username: params[1],
                amount:params[3] == null ? (isNaN(+LoadCurrency) ? 0 : LoadCurrency) : params[3],
                detail: params[4],
              });
      
              if (params.length > 1) {
                this.props.loader(true);
                this.props.GetMerchantData(params[1], this.props, this.state).then((res) => {
                  if (res.user) {
                    this.setState({
                      merchantdata: res.user,
                      amount: !isNaN(+params[2]) ? params[2] : params[3],
                      currency: !isNaN(+LoadCurrency)
                        ? res.user.organisation
                          ? res.user.organisation.merchantCurrency
                            ? res.user.organisation.merchantCurrency
                            : "USD"
                          : res.user.currencyName
                          ? res.user.currencyName
                          : "USD"
                        : LoadCurrency
                        ? LoadCurrency
                        : res.user.organisation
                        ? res.user.organisation.merchantCurrency
                          ? res.user.organisation.merchantCurrency
                          : "USD"
                        : res.user.currencyName
                        ? res.user.currencyName
                        : "USD",
                    });
                    this.props
                      .GenerateAddress(res.user.email)
                      .then((addressresponse) => {
                        this.props.loader(false);
                        this.setState({
                          depositAddress: addressresponse.data.address,
                        });
                      });
                  } else {
                    this.props.loader(false);
                  }
                });
              }
            }
          );
        });


        // this.handleCurrencyList();
      }
    } else {
      this.setState({
        amount: paymentreload ? paymentreload.amount : "",
        currency: paymentreload ? paymentreload.currency : "",
        detail: paymentreload ? paymentreload.invoiceDetail : "",
        merchantdata: paymentreload ? paymentreload.merchantdata : null,
        depositAddress: paymentreload ? paymentreload.depositAddress : "",
        merchantname: paymentreload ? paymentreload.merchantname : "",
      });
      localStorage.removeItem("paymentDetails");
      this.handleCurrencyList();
    }
  }
  handleChange = (e) => {
    var isvalidate = true;
    isvalidate = this.validate(e.target.value, e.target.name)
    this.setState({ [e.target.name]:  (e.target.name =="detail" ? e.target.value.substr(0,25) :e.target.value)});
  };


  handleSubmit = (e) => {
    var Paysucces = this.paymentValidation();

    if (Paysucces) {
      this.props.history.push({
        pathname: "/payment",
        state: {
          merchantdata: this.state.merchantdata,
          invoiceDetail: this.state.detail,
          currency: this.state.currency,
          amount: this.state.amount,
          depositAddress: this.state.depositAddress,
          merchantname: this.props.location.pathname.split("/")[1],
          GoBack: true,
        },
      });
    }
    var data = {
      invoiceDetail: this.state.detail,
      currency: this.state.currency,
      amount: this.state.amount,
      merchantdata: this.state.merchantdata,
      merchantname: this.props.location.pathname.split("/")[1],
      depositAddress : this.state.depositAddress
    };
    localStorage.setItem("paymentDetails", JSON.stringify(data));
  };

  paymentValidation = () => {
    var { isValid, errorblock } = handlePaymentValidation(this.state);
    this.setState({
      error: errorblock,
    });
    return isValid;
  };

  validate = (val, name) => {
    var { isvalid, errorblock } = validatePay(this.state.error, val, name);
    this.setState({ error: errorblock });
    return isvalid;
  };

  handleSelect = (selectOptions) => {
    this.setState({ currency: selectOptions.currencyName });
  };

  handleCurrencyList = () => {
    this.props.GetCurrencyList().then((res) => {
      var currencyName =
        res && res.countryList
          ? res.countryList.map((el) => ({
              ...el,
              label: el.currencyName,
              value: el.currencyName,
            }))
          : [];
      let SortCurrency = _.sortBy(currencyName, "value");
      var currencyUnique = _.uniqBy(SortCurrency, "value");
      this.setState(
        {
          selectOptions: currencyUnique,
        },

        () => {}
      );
    });
  };

  handleCustomOption = ({ label, innerProps, isDisabled }) => {
    const { selectOptions } = this.state;
    selectOptions.filter((curr) => curr.currencyName === "USD")
    const acitveLabel = selectOptions
      ? selectOptions.filter((el) => el.label === label)
      : [];

    return !isDisabled ? (
      <div className="dropdownMenu" {...innerProps}>
        <div className="col-md-12 d-flex">
          <div className="col">{acitveLabel[0].currencyName}</div>
        </div>
      </div>
    ) : null;
  };

  render() {
    const { merchantdata } = this.state;
    return (
      <div className="payment-container">
        <Header></Header>
        <div className="payment-content">
          <PaymentDashboard />
         
          <div className="block-container">
          {merchantdata && 
          <div>
            <div className="client-logo">
              <img
                src={
                  merchantdata
                    ? backendUrl +
                      (merchantdata.profilePic
                        ? merchantdata.profilePic
                        : "/SiteImage/default_user.jpg")
                    : backendUrl + "/SiteImage/default_user.jpg"
                }
                alt=""
              />
            </div>

            <div className="client-title">
              <p>
                Make a payment to{" "}
                <strong>
                  {merchantdata
                    ? merchantdata.organisation
                      ? merchantdata.organisation.orgName
                      : merchantdata.firstName + " " + merchantdata.lastName
                    : ""}
                </strong>
              </p>
            </div>
            <div className="payment-details">
              <p>
                {merchantdata && merchantdata.organisation
                  ? merchantdata.organisation.bRegNo
                  : ""}
              </p>
              <p>
                {" "}
                {merchantdata && merchantdata.organisation
                  ? merchantdata.organisation.orgAddress
                  : ""}{" "}
                {merchantdata && merchantdata.organisation
                  ? merchantdata.organisation.city
                  : ""}{" "}
                {merchantdata && merchantdata.organisation
                  ? merchantdata.organisation.state
                  : ""}{" "}
                {merchantdata && merchantdata.location}
              </p>
            </div>

            <div className="payment-enter">
              <input
                type="number" 
                name="amount" autoComplete="off"
                value={this.state.amount}
                onChange={(e) => this.handleChange(e)}
                placeholder="0.00"
                className={`form-control  ${
                  this.state.error && this.state.error.amount ? "required" : ""
                }`}
              />

              <Select
                className="blockselect"
                placeholder={<div></div>}
                onChange={this.handleSelect}
                options={this.state.selectOptions}
                //components={{ Option: this.handleCustomOption }}
                value={{
                  value: this.state.currency,
                  label: this.state.currency,
                }}
              />
            </div>

       <div className="payment-details">
              <p>Scan the QR code on the next screen with your</p>
              <p>Forestcoin app to pay with FC</p>
            </div>

            <div className="payment-msg">
              <textarea
                type="text"
                name="detail"
                value={this.state.detail}
                className={`form-control blockinput ${
                  this.state.error && this.state.error.detail ? "required" : ""
                }`}
                placeholder="Message*"
                onChange={(e) => this.handleChange(e)}
              ></textarea>
            </div>   
           {this.state.error && this.state.error.detail && this.state.error.detail !='required' &&
            <div className="maxlimit"><span className="maxerror">{this.state.error.detail}</span></div>}

            <div className="form-group ">
              <button
                className="form-control blockbutton"
                onClick={this.handleSubmit}
              >
                Go
              </button>
            </div>
            </div>
           }
          </div>
        
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { Payment } = state;

  return {
    Payment,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    GetCurrencyList,
    GetMerchantData,
    GenerateAddress,
    loader,
  })(Payment)
);
