import React, { Component } from "react";
import QRCode from "react-qr-code";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { isDesktop, isTablet, isMobileSafari, isMobile } from 'react-device-detect';

class QRPage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { openOrder, depositAddress, amount, currency, userName, userCountry } = this.props;
    return (
      <div>
        <div className="top-content">
          <div>
              <div className="payto">Pay to : <span className="paytoname">{userName} : {userCountry} </span></div>
          </div>
          <div className="QRamount">
            <p>
            {openOrder ? openOrder.volumeTrade : 0} <span className="fctext">FC</span>
            </p>
          </div>

          <div className="QRusd">
            <p>({currency} {amount})</p>
          </div>

          <div className="QRconvert">
            <p>{openOrder ? openOrder.fiatCurrencyText : ''}</p>
          </div>
        </div>
        <div className="QRcode-container">
          <div className="info-text">
            <p>Rate will refresh in <span>{this.props.data.seconds}</span> seconds</p>
          </div>

          <div className="QR-main">
              <QRCode value={openOrder ? openOrder.barcodeText : ''} size={180} title="Forestcoin"  fgColor={openOrder && openOrder.barcodeText ? '#000000' : '#ffffff'}/>
          </div>

          <div className="depositadd">
          <CopyToClipboard text={depositAddress}
          onCopy={() => this.setState({copied: true})}>
          <span>Deposit Address:</span>
          </CopyToClipboard>
          { depositAddress }
          
          </div>
          <div>
          
          </div>

          <div className="depositadd">
          <span>Invoice detail: </span>{this.props.data.invoiceDetail}
          </div>

           { isDesktop && !isTablet && !isMobileSafari &&  !isMobile ?
           <div>
              <div>
                <button className="refresh-button" onClick={()=>this.props.clickRefresh()}>REFRESH RATE</button>
              </div>
            </div>
            : <div>
                <div>
                  <button className="refresh-button" onClick={()=>this.props.clickPayNow()}>PAY NOW</button>
                </div>
            </div>
           }
          <div>
            <button className="cancel-button" onClick={()=>this.props.clickCancel()}>CANCEL</button>
          </div>
        </div>
      </div>
    );
  }
}

export default QRPage;
