import _ from "lodash";

function handlePaymentValidation(data) {
    const { amount, detail } = data;
    let errorblock = {};

    if (_.isEmpty(amount) || amount === 0) {
      errorblock.amount = "required";
    }

    if (_.isEmpty(detail)) {
      errorblock.detail = "required";
    }
    else if(detail.length>25){
      errorblock.detail = "Maximum limit should not be greater than 25 character";
    }

    return {
      errorblock,
      isValid: _.isEmpty(errorblock),
    };
}

function validatePay(state, val, name) {
  var errorblock = state;

  if (_.isEmpty(val)) {
    errorblock[name] = "required";
  } else {
    delete errorblock[name];
  }

  if(name == "detail" && val.length>25){
    errorblock[name] = "Maximum limit should not be greater than 25 character";
  }


  return {
    errorblock,
    isvalid: _.isEmpty(errorblock),
  };
}

export { handlePaymentValidation, validatePay };
