import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as signalR from "@microsoft/signalr";
import { signalRUrl, deepLink, backendUrl } from "./../../setup";
import PaymentDashboard from "../Payment/paymentDashboard";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import QRPage from "./QRPage";
import {
  GetOpenOrder,
  GetTransactionRequest,
  GenerateAddress,
  GetMerchantData,
  loader
} from "../../redux/Actions/payment";
import _ from "lodash";

import "@fortawesome/fontawesome-free/js/all.js";
import "./QRCode.scss";
class QRCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openOrder: null,
      depositAddress: '',
      fundType: 'USDC',
      amount: 0,
      currency: '',
      userId: 0,
      userName: '',
      userEmail: '',
      userCountry: '',
      userState: '',
      userAddress: '',
      userWebsite: '',
      userProfile: '',
      invoiceDetail: '',
      signalrConnectionID: '',
      merchantdata: this.props.location && this.props.location.state && this.props.location.state.merchantdata ? this.props.location.state.merchantdata : null,
      seconds: 8,
      refreshSecond: 1000,
      transactionRequestID: ''
    };
    this.connection = null;
    this.onNotifReceived = this.onNotifReceived.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state) {

      if (this.props.location.state.merchantname) {

        this.props.loader(true);
        this.props.GetMerchantData(this.props.location.state.merchantname, this.props, this.state).then((res) => {
          if (res.user) {
            this.props.GenerateAddress(res.user.email).then((addressresponse) => {
              var merchantdata = res.user;
              this.setState({
                userId: merchantdata.userId,
                userName: merchantdata.firstName,
                userEmail: merchantdata.email,
                userCountry: merchantdata.location,
                userState: merchantdata.state == null ? '' : merchantdata.state,
                userProfile: merchantdata.profilePic,
                invoiceDetail: this.props.location.state.invoiceDetail,
                currency: this.props.location.state.currency === 'Merchant'
                ? res.user.organisation
                  ? res.user.organisation.merchantCurrency
                    ? res.user.organisation.merchantCurrency
                    : "USD"
                  : res.user.currencyName
                  ? res.user.currencyName
                  : "USD"
                : this.props.location.state.currency
                ? this.props.location.state.currency
                : res.user.organisation
                ? res.user.organisation.merchantCurrency
                  ? res.user.organisation.merchantCurrency
                  : "USD"
                : res.user.currencyName
                ? res.user.currencyName
                : "USD",
                amount: this.props.location.state.amount,
                depositAddress: addressresponse.data.address,
                fundType: res.user.organisation ? res.user.organisation.receivePaymentType ? res.user.organisation.receivePaymentType
                    : "USDC" : "USDC" 
              }, function () {
                this.callSignalRConnection();
              });
            });
          } else {
            this.props.loader(false);
          }
        });
      }
      else {
        var merchantdata = this.props.location.state.merchantdata;
        if (merchantdata) {
          this.setState({
            userId: merchantdata.userId,
            userName: merchantdata.firstName,
            userEmail: merchantdata.email,
            userCountry: merchantdata.location,
            userState: merchantdata.state == null ? '' : merchantdata.state,
            userProfile: merchantdata.profilePic,
            invoiceDetail: this.props.location.state.invoiceDetail,
            currency: this.props.location.state.currency,
            amount: this.props.location.state.amount,
            depositAddress: this.props.location.state.depositAddress,
            fundType: merchantdata.organisation ? merchantdata.organisation.receivePaymentType ? merchantdata.organisation.receivePaymentType
                    : "USDC" : "USDC" 
          }, function () {
            this.callSignalRConnection();
          });

        }
      }
    } else {
      let params = this.props.location.pathname.split("/");
      this.setState({
        username: params[1],
        amount: params[3] == null ? (isNaN(+params[2]) ? 0 : params[2]) : params[3],
        detail: params[4],
        currency: params[2]
      });

      if (params.length > 1) {
        this.props.loader(true);
        this.props.GetMerchantData(params[1], this.props, this.state).then((res) => {
          if (res.user) {
            this.props.GenerateAddress(res.user.email).then((addressresponse) => {
              var merchantdata = res.user;
              console.log(merchantdata,'merchangtdata')
              this.setState({
                userId: merchantdata.userId,
                userName: merchantdata.firstName,
                userEmail: merchantdata.email,
                userCountry: merchantdata.location,
                userState: merchantdata.state == null ? '' : merchantdata.state,
                userProfile: merchantdata.profilePic,
                invoiceDetail: this.state.detail,
                currency: this.state.currency,
                amount: this.state.amount,
                userAddress: merchantdata.organisation ? merchantdata.organisation.orgAddress : merchantdata.orgAddress,
                userWebsite: merchantdata.organisation ? merchantdata.organisation.orgWebsite : merchantdata.orgWebsite,
                depositAddress: addressresponse.data.address,
                fundType:  res?.user?.organisation?.receivePaymentType || "USDC"
              }, function () {
                this.callSignalRConnection();
              });
            });
          } else {
            this.props.loader(false);
          }
        });
      }
    }
  }

  callSignalRConnection = () => {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(signalRUrl)
      .withAutomaticReconnect()
      .build();

    this.connection
      .start()
      .then((e) => {

        var connectionId = this.connection.connection.connectionId;
        //console.log(this.connection, 'SignalR')
        console.info("SignalR Connected : " + connectionId);
        this.setState({ signalrConnectionID: connectionId })

        this.loadQRCode(connectionId, false);
        this.connection.on("sendmessage", (type, message) => this.onNotifReceived(type, message));

      })
      .catch((err) => console.error("SignalR Connection Error: ", err));

  }

  loadQRCode = (signalrConnectionID, isRefresh) => {
    var openOrder = {
      "fundsType": this.state.fundType,
      "currencyName": this.state.currency,
      "amount": this.state.amount,
      "userId": this.state.userId,
      "bSimplex": false,
      "userCountryName": this.state.userCountry,
      "userStateName": this.state.userState,
    };
    this.props.loader(!isRefresh);
    this.props.GetOpenOrder(openOrder, this.props, this.state).then((openorderresponse) => {
      var openorderres = openorderresponse;

      openorderres.barcodeText =  openorderres && openorderres.barcodeText  ? openorderres.barcodeText
        .replace('{m_id}', this.state.userId)
        .replace('{m_nm}', this.state.userName)
        .replace('{m_add}', this.state.userAddress)
        .replace('{m_web}', this.state.userWebsite)
        .replace('{m_loc}', this.state.userCountry)
        .replace('{m_img}', this.state.userProfile)
        .replace('{fcadd}', this.state.depositAddress) :'';

      var transactionRequest = {
        "fundsType": this.state.fundType,
        "selectedCurrency": this.state.currency,
        "amount": this.state.amount,
        "merchantUserId": this.state.userId,
        "volumeTrade": openorderres.volumeTrade,
        "signalrConnectionID": signalrConnectionID ?? this.state.signalrConnectionID,
        "transactionTypeID": 2,
        "invoiceDetails": this.state.invoiceDetail,
        "barcodeText": openorderres.barcodeText,
      };
      if(openorderres.statusCode === 1) {
        if(!isRefresh) {
          this.props.GetTransactionRequest(transactionRequest, this.props, this.state).then((transactionresponse) => {
            openorderres.barcodeText = openorderres.barcodeText.replace('{trn_id}', transactionresponse.transactionRequest.transactionRequestID)
            this.setState({ openOrder: openorderres, seconds: openorderres.refreshSecond, transactionRequestID: transactionresponse.transactionRequest.transactionRequestID}, function() {
              this.interval = setInterval(() => this.LoadSeconds(), this.state.refreshSecond);
            });
            this.props.loader(false);
            
          });
        } else {
          openorderres.barcodeText = openorderres.barcodeText.replace('{trn_id}', this.state.transactionRequestID)
          this.setState({ openOrder: openorderres, seconds: openorderres.refreshSecond }, function() {
            this.interval = setInterval(() => this.LoadSeconds(),  this.state.refreshSecond);
          });

        }
      } else {
        this.props.loader(false);
      }
    });
  }

  componentWillUnmount() {
    this.connection.stop();
    clearInterval(this.interval);
  }

  handleRefresh = () => {
    clearInterval(this.interval);
    this.loadQRCode(this.state.signalrConnectionID, true)
  }

  handlePayNow = () => {
    console.log(this.state)
    let deepLinkURL = (deepLink + '/?barcodestring=' + this.state.openOrder.barcodeText);
    console.log(deepLinkURL)
    window.location.href = deepLinkURL;
  }

  handleCancel = (e) => {
    var his = this.props.history
    var previousload = this.props.history.location && this.props.history.location.state ? this.props.history.location.state.GoBack :''
    if (his.length <=2 || previousload === undefined) {
      //alert("/" + (this.props.location.state.merchantname ?? this.state.username) + "/" + this.state.currency + "/" + this.state.amount)
      this.props.history.push({
        pathname: "/" + (this.props.location.state.merchantname ?? this.state.username) + "/" + this.state.currency + "/" + this.state.amount
      });
    } else {
      localStorage.setItem("GoBack", 'true');
      let history = this.props.history;
      history.goBack();
    }
  }

  onNotifReceived(type, message) {
    console.log("I just received a notification!!!", message);
    this.connection.stop();
    clearInterval(this.interval);

    this.props.history.push({
      pathname: "/success",
      state: {
        confirmationdata: JSON.parse(message),
        amount: this.state.amount,
        merchantname: this.props.location.state.merchantname ?? this.state.username,
        invoiceDetail: this.state.detail,
        currency: this.state.currency,
        depositAddress: this.state.depositAddress,
      },
    });
  }

  LoadSeconds = () => {
    if (this.state.seconds > 0) {
      this.setState(prevState => ({
        seconds: prevState.seconds - 1
      }));
    } else if (this.state.seconds === 0) {
      clearInterval(this.interval);
      this.handleRefresh();
    }
  }
  render() {
    return (
      <div className="payment-container">
        <Header></Header>
        <PaymentDashboard />
        <div className="QR-container">
        {this.state.openOrder && 
          <QRPage data={this.state} openOrder={this.state.openOrder} depositAddress={this.state.depositAddress} clickRefresh={this.handleRefresh} clickPayNow={this.handlePayNow} clickCancel={this.handleCancel}

          amount={this.state.amount} currency={this.state.currency} userName={this.state.userName} userCountry={this.state.userCountry} />
        }
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { payment } = state;

  return {
    payment,
  };
};


export default withRouter(
  connect(mapStateToProps, { GetOpenOrder, GetTransactionRequest, GenerateAddress, GetMerchantData, loader })(QRCode)
);
