import instanceApi from "../../api";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import "../../redux/redux.scss";
export const GET_CURRENCY_LIST = "GET_CURRENCY_LIST ";
export const GET_MERCHANT_DATA = "GET_MERCHANT_DATA ";
export const GET_OPEN_ORDER = "GET_OPEN_ORDER"
export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST"
export const GET_GENERATE_ADDRESS = "GET_GENERATE_ADDRESS"
export const LOADER_LOAD = "LOADER_LOAD"

export const GetCurrencyList = () => async (dispatch, getState) => {
  try {
    const { data } = await instanceApi({
      url: "/api/GetCountries",
      method: "GET",
      headers: {},
    });

    if (data && data.statusCode === 1) {
      dispatch({
        type: GET_CURRENCY_LIST,
        payload: data,
      });
    }else{
      Swal.fire({  
        title: 'Forestcoin',  
        html: data.status === 'Failure' ? data.errorMessage : data.status,
        icon: 'error'
        
      }).then(function() {
        return data;
      });
    }
    return data;
  } catch (e) {}
};

export const GetMerchantData = (username, props, state) => async (dispatch, getState) => {
  try {
    const { data } = await instanceApi({
      url: "/api/GetMerchantdataByKey/" + username,
      method: "GET",
      headers: {},
    });

    if (data && data.statusCode === 1) {
      dispatch({
        type: GET_MERCHANT_DATA,
        payload: data.user,
      });
    }
    else{
      Swal.fire({  
        title: 'Forestcoin',  
        html: data.status === 'Failure' ? data.errorMessage : data.status,
        icon: 'error'
        
      }).then(function() {
        var his = props.history
        var previousload = props.history.location && props.history.location.state ? props.history.location.state.GoBack :''
        if (his.length <=2 || previousload === undefined) {
          //alert("/" + (this.props.location.state.merchantname ?? this.state.username) + "/" + this.state.currency + "/" + this.state.amount)
          props.history.push({
            pathname: "/" + (props.location.state.merchantname ?? state.username) + "/" + state.currency + "/" + state.amount
          });
        } else {
          props.history.goBack(-1)
        }
    }); 
    }
    return data;
  } catch (e) {}
};

export const GetOpenOrder = (openOrder, props, state) => async (dispatch, getState) => {
  try {
    const { data } = await instanceApi({
      url: "/api/GetOpenOrder",
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      data: openOrder
    });

    if (data && data.statusCode === 1) {
      dispatch({
        type: GET_OPEN_ORDER,
        payload: data,
      });
    }else{
      Swal.fire({  
        title: 'Forestcoin',  
        html: data.status === 'Failure' ? data.errorMessage : data.status,
        icon: 'error', 
        
      }).then(function() {
        var his = props.history
        var previousload = props.history.location && props.history.location.state ? props.history.location.state.GoBack :''
        if (his.length <=2 || previousload === undefined) {
          //alert("/" + (this.props.location.state.merchantname ?? this.state.username) + "/" + this.state.currency + "/" + this.state.amount)
          props.history.push({
            pathname: "/" + (props.location.state.merchantname ?? state.username) + "/" + state.currency + "/" + state.amount
          });
        } else {
          props.history.goBack(-1)
        }
      });
    }
    return data;
  } catch (e) { console.log(e)}
};

export const GetTransactionRequest = (transactionRequest, props, state) => async (dispatch, getState) => {
  try {
    const { data } = await instanceApi({
      url: "/api/GetTransactionRequestId",
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      data: transactionRequest
    });

    if (data && data.statusCode === 1) {
      dispatch({
        type: GET_TRANSACTION_REQUEST,
        payload: data,
      });
    }else{
      Swal.fire({  
        title: 'Forestcoin',  
        html: data.status === 'Failure' ? data.errorMessage : data.status,
        icon: 'error'
        
      }).then(function() {
        var his = props.history
        var previousload = props.history.location && props.history.location.state ? props.history.location.state.GoBack :''
        if (his.length <=2 || previousload === undefined) {
          //alert("/" + (this.props.location.state.merchantname ?? this.state.username) + "/" + this.state.currency + "/" + this.state.amount)
          props.history.push({
            pathname: "/" + (props.location.state.merchantname ?? state.username) + "/" + state.currency + "/" + state.amount
          });
        } else {
          props.history.goBack(-1)
        }
      });
    }
    return data;
  } catch (e) {}
};

export const GenerateAddress = (email) => async (dispatch, getState) => {
  try {
    const { data } = await instanceApi({
      url: "/api/EFGenerateAddress/FC",
      method: "GET",
      headers: {'Email': email},
    });

    if (data && data.statusCode === 1) {
      dispatch({
        type: GET_GENERATE_ADDRESS,
        payload: data,
      });
    }else{
      Swal.fire({  
        title: 'Forestcoin',  
        html: data.status === 'Failure' ? data.errorMessage : data.status,
        icon: 'error'
        
      }).then(function() {
        return data;
      });
    }
    return data;
  } catch (e) {}
};

export const loader = (
    
  isloading=false
  
 ) => {
  
   return {
    
     type: LOADER_LOAD,
     payload:isloading,
   };
 };